import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import { Form, FormElement, Field, FormRenderProps } from "@progress/kendo-react-form";

import { passwordLengthValidator, repeatPasswordValidator } from "@src/common/util";
import { RECAPTCHA_KEY, shouldDisableCaptcha } from "@src/common/config";
import axios from "@src/common/http";
import { Button, Card, CardContent, LabelInput, Icon, LoadingPanel } from "@components/common";

import "./ResetPassword.scss";

const baseClass = "acl-page-auth";

type ParamTypes = {
    token: string;
};

const repeatNewPasswordValidator = (repeatNewPassword, getField): string =>
    passwordLengthValidator(repeatNewPassword) || repeatPasswordValidator(repeatNewPassword, getField("password"));

const ResetPassword: React.FC<{}> = () => {
    const { token } = useParams<ParamTypes>();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const recaptchaRef = React.useRef(null);

    async function handleSubmit(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();
        setSubmitting(true);

        // Wait for recaptcha to finish validation before submitting the form
        const recaptcha = shouldDisableCaptcha ? "" : await recaptchaRef.current.executeAsync();
        // const response = await axios.post(`/portal/reset-password/${token}`, { ...formData, recaptcha });
        const response = await axios.post(`/reset-password/${token}`, { ...formData, recaptcha });
        const { status } = response;

        if (status === 200) {
            setSubmitted(true);
        } else {
            !shouldDisableCaptcha && recaptchaRef.current.reset();
        }

        setSubmitting(false);
    }

    async function checkToken(): Promise<void> {
        if (submitted) {
            return;
        }

        setLoading(true);
        // const response = await axios.get(`/portal/request-reset-password?token=${token}`);
        const response = await axios.get(`/request-reset-password?token=${token}`);
        setLoading(false);

        if (response.status !== 200) {
            navigate("/sign-in");
        }
    }

    function handleBack(): void {
        setSubmitted(false);
        navigate("/home");
    }

    useEffect(() => {
        checkToken();
    }, []);

    return (
        <main className={`${baseClass} ${baseClass}--login`}>
            {loading && <LoadingPanel />}
            {!loading && (
                <Form
                    onSubmit={handleSubmit}
                    render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                        <FormElement className={`${baseClass}__form`} noValidate={true}>
                            <h1>Reset Password</h1>
                            {submitted && (
                                <Card className={`${baseClass}__content`}>
                                    <CardContent>
                                        <h3 className={`${baseClass}__title`}>Reset Password</h3>
                                        <p>Your password was successfully changed.</p>
                                        <div className={`k-form-buttons ${baseClass}__actions`}>
                                            <Button themeColor="primary" type="submit" onClick={handleBack}>
                                                Back to Login
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            )}
                            {!submitted && (
                                <Card className={`${baseClass}__content`}>
                                    <CardContent>
                                        <h3 className={`${baseClass}__title`}>Reset Password</h3>
                                        <div className={`${baseClass}__password-wrapper`}>
                                            <Field
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                label="Password"
                                                id="txtPassword"
                                                ariaDescribedBy="txtPasswordError"
                                                component={LabelInput}
                                                validator={passwordLengthValidator}
                                                validityStyles={false}
                                                required={true}
                                                autoFocus={true}
                                                disabled={submitting}
                                                className={`${baseClass}__field`}
                                            />
                                            <Icon
                                                name={showPassword ? "view" : "view-off"}
                                                className={`${baseClass}__view-icon`}
                                                color="secondary"
                                                onClick={(): void => setShowPassword(!showPassword)}
                                            />
                                        </div>
                                        <div className={`${baseClass}__password-wrapper`}>
                                            <Field
                                                type={showRepeatPassword ? "text" : "password"}
                                                name="repeatPassword"
                                                label="Confirm new password"
                                                id="txtRepeatPassword"
                                                ariaDescribedBy="txtRepeatPasswordError"
                                                component={LabelInput}
                                                validator={repeatNewPasswordValidator}
                                                validityStyles={false}
                                                required={true}
                                                disabled={submitting}
                                                className={`${baseClass}__field`}
                                            />
                                            <Icon
                                                name={showRepeatPassword ? "view" : "view-off"}
                                                className={`${baseClass}__view-icon`}
                                                color="secondary"
                                                onClick={(): void => setShowRepeatPassword(!showRepeatPassword)}
                                            />
                                        </div>

                                        <div className={`k-form-buttons ${baseClass}__actions`}>
                                            <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting}>
                                                {submitting && <Icon name="loading" spacing="right" />}
                                                Change Password
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            )}
                            {!shouldDisableCaptcha && <ReCAPTCHA ref={recaptchaRef} sitekey={RECAPTCHA_KEY} badge="bottomright" size="invisible" />}
                        </FormElement>
                    )}
                />
            )}
        </main>
    );
};

export default ResetPassword;
