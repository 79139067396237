import React, { useState } from "react";

import { Form, FormElement, Field, FormRenderProps } from "@progress/kendo-react-form";

import { passwordValidator, samePasswordValidator, repeatPasswordValidator, passwordLengthValidator } from "@src/common/util";
import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import axios from "@src/common/http";
import { Button, LabelInput, Icon } from "@components/common";

import "./ChangePassword.scss";

const baseClass = "acl-change-password";

const newPasswordsValidator = (newPassword, getField): string =>
    passwordLengthValidator(newPassword) || samePasswordValidator(newPassword, getField("oldPassword"));

const repeatNewPasswordValidator = (repeatNewPassword, getField): string =>
    passwordLengthValidator(repeatNewPassword) || repeatPasswordValidator(repeatNewPassword, getField("newPassword"));

const ChangePassword: React.FC<{}> = () => {
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [appUser, _setAppUser] = useAppUserContext();
    const { sendNotification } = useNotificationContext();

    async function handleSubmit(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();
        setSubmitting(true);

        const { email } = appUser;
        const { oldPassword, newPassword } = formData;
        // const response = await axios.post(`/portal/changePassword`, { email, oldPassword, newPassword });
        const response = await axios.post(`/changePassword`, { email, oldPassword, newPassword });
        const { status } = response;

        if (status === 200) {
            sendNotification({
                timeout: 10000,
                type: "success",
                message: "Password succesfully changed",
            });
        }

        setSubmitting(false);
    }

    return (
        <div className={baseClass} data-testid="form">
            <Form
                aria-busy={submitting ? "true" : null}
                onSubmit={handleSubmit}
                render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                    <FormElement className={`${baseClass}__form`} noValidate={true}>
                        <div className={`${baseClass}__field-group`}>
                            <Field
                                type="password"
                                name="oldPassword"
                                label="Old Password"
                                id="txtOldPassword"
                                ariaDescribedBy="txtOldPasswordError"
                                component={LabelInput}
                                validator={passwordValidator}
                                validityStyles={false}
                                required={true}
                                disabled={submitting}
                                className={`${baseClass}__field`}
                            />
                        </div>
                        <div className={`${baseClass}__field-group`}>
                            <Field
                                type="password"
                                name="newPassword"
                                label="New Password"
                                id="txtNewPassword"
                                ariaDescribedBy="txtNewPasswordError"
                                component={LabelInput}
                                validator={newPasswordsValidator}
                                validityStyles={false}
                                required={true}
                                disabled={submitting}
                                className={`${baseClass}__field`}
                            />
                            <Field
                                type="password"
                                name="repeatNewPassword"
                                label="Repeat New Password"
                                id="txtRepeatNewPassword"
                                ariaDescribedBy="txtRepeatNewPasswordError"
                                component={LabelInput}
                                validator={repeatNewPasswordValidator}
                                validityStyles={false}
                                required={true}
                                disabled={submitting}
                                className={`${baseClass}__field`}
                            />
                        </div>
                        <div className={`${baseClass}__footer`}>
                            <div className={`${baseClass}__actions`}>
                                <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting}>
                                    {submitting && <Icon name="loading" spacing="right" />}
                                    Save
                                </Button>
                            </div>
                        </div>
                    </FormElement>
                )}
            />
        </div>
    );
};

export default ChangePassword;
