import React from "react";

import { MultiSelect } from "@progress/kendo-react-dropdowns";
import { Label, Error } from "@progress/kendo-react-labels";
import { FieldWrapper, FieldRenderProps } from "@progress/kendo-react-form";

interface Data {
    text: string;
    id: string;
}

interface LabelMultipleSelectProps extends FieldRenderProps {
    data: Data[];
    textField: string;
    dataItemKey: string;
}

const LabelMultipleSelect: React.FC<LabelMultipleSelectProps> = (fieldRenderProps: LabelMultipleSelectProps) => {
    const { validationMessage, visited, label, id, valid, disabled, ariaDescribedBy, data, textField, dataItemKey, ...others } = fieldRenderProps;
    const showValidationMessage = visited && validationMessage;

    return (
        <FieldWrapper>
            <Label editorId={id} editorValid={valid} editorDisabled={disabled}>
                {label}
            </Label>
            <MultiSelect
                id={id}
                valid={valid}
                disabled={disabled}
                ariaDescribedBy={ariaDescribedBy}
                data={data}
                textField={textField}
                dataItemKey={dataItemKey}
                {...others}
            />
            {showValidationMessage && <Error id={ariaDescribedBy}>{validationMessage}</Error>}
        </FieldWrapper>
    );
};

export default LabelMultipleSelect;
