import React from "react";
import { Card, CardContent, CardHeader } from "@components/common";
import "../InitiateOnboarding.scss";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DistribitionForms } from "./types";
import { distrinutionFormDetails } from "../getInitialForms";

const baseClass = "acl-page-initiate-onboarding";

type Props = {
    state: DistribitionForms;
    setState: React.Dispatch<React.SetStateAction<DistribitionForms>>;
};

const StepDistribution: React.FC<Props> = ({ state, setState }) => {
    const { form: distributionFields, fundProvider, authorized, distributed } = state;

    const handleChange = (e): void => {
        setState(prevState => ({ ...prevState, fundProvider: e.value === "Yes" }));
    };

    const handleDistributedChange = (e): void => {
        setState(prevState => ({ ...prevState, distributed: e.value === "Yes" }));
    };

    const handleAuthorizedChange = (e): void => {
        setState(prevState => ({ ...prevState, authorized: e.value === "Yes" }));
    };

    const updateForm = (fieldName, newValue): void => {
        setState(prevState => {
            const form = prevState.form;

            return { ...prevState, form: { ...form, [fieldName]: newValue } };
        });
    };

    return (
        <Card className={`${baseClass}__overview`}>
            <CardContent>
                <div className={`${baseClass}__indentedContent`}>
                    <div className={`${baseClass}__contentWrapper`}>
                        <p>Is the Fund Provider going to distribute / offer the funds by themselves in Switzerland?</p>
                        <div className={`${baseClass}__radioWrapper`}>
                            <RadioButton
                                name="turn"
                                label="Yes"
                                value="Yes"
                                className={`${baseClass}__radioButtonYes`}
                                onChange={handleChange}
                                checked={fundProvider}
                            />
                            <RadioButton
                                name="turn"
                                label="No"
                                value="No"
                                className={`${baseClass}__radioButtonNo`}
                                onChange={handleChange}
                                checked={fundProvider === false}
                            />
                        </div>
                        {fundProvider && (
                            <>
                                <p>Is the Fund Provider authorized by its regulator to distribute investment funds?</p>
                                <div className={`${baseClass}__radioWrapper`}>
                                    <RadioButton
                                        name="providerAuthorized"
                                        label="Yes"
                                        value="Yes"
                                        className={`${baseClass}__radioButtonYes`}
                                        onChange={handleAuthorizedChange}
                                        checked={authorized}
                                    />
                                    <RadioButton
                                        name="providerAuthorized"
                                        label="No"
                                        value="No"
                                        className={`${baseClass}__radioButtonNo`}
                                        onChange={handleAuthorizedChange}
                                        checked={authorized === false}
                                    />
                                </div>
                            </>
                        )}
                        <p>Will the Third Party be appointed to distribute / offer the fund(s) in Switzerland?</p>
                        <div className={`${baseClass}__radioWrapper`}>
                            <RadioButton
                                name="providerDistributed"
                                label="Yes"
                                value="Yes"
                                className={`${baseClass}__radioButtonYes`}
                                onChange={handleDistributedChange}
                                checked={distributed}
                            />
                            <RadioButton
                                name="providerDistributed"
                                label="No"
                                value="No"
                                className={`${baseClass}__radioButtonNo`}
                                onChange={handleDistributedChange}
                                checked={distributed === false}
                            />
                        </div>
                    </div>

                    {distributed && (
                        <Card className={`${baseClass}__distributorContactForm`}>
                            <CardHeader>
                                <h3>Third Party Distributor</h3>
                            </CardHeader>
                            <CardContent>
                                {Object.entries(distributionFields).map(([fieldKey, _fieldValue]) => (
                                    <div key={fieldKey} className={`${baseClass}__stepDistributionFields`}>
                                        <label>{distrinutionFormDetails[fieldKey]}</label>
                                        <Input value={distributionFields[fieldKey]} onChange={(e): void => updateForm(fieldKey, e.value)} />
                                    </div>
                                ))}
                            </CardContent>
                        </Card>
                    )}
                </div>
            </CardContent>
        </Card>
    );
};

export default StepDistribution;
