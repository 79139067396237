export interface IRole {
    code: string;
    name: string;
}
export interface IUser {
    formattedStatus?: string;
    activated?: string;
    email: string;
    firstName: string;
    lastName: string;
    jobTitle: string;
    companyName?: string;
    statusCode?: number;
    roles: IRole[];
}

export enum ERole {
    PortalAdmin = "PORTA",
    PortalViewer = "PORTV",
    DataManager = "DATAM",
    DocumentManager = "DOCUM",
    CommissionManager = "COMM",
}

export enum WWMessage {
    Start = "START",
    Update = "UPDATE",
    Stop = "STOP",
    Query = "QUERY",
    Result = "RESULT",
}

export enum PSChannel {
    Error = "ERROR",
}

export interface IPointOfContact {
    email: string;
    fullName: string;
    phoneNumber: string;
}

const fallbackPointOfContact: IPointOfContact = { email: "", fullName: "", phoneNumber: "" };

export interface IAppUser {
    firstName: string;
    lastName: string;
    roles: Array<string>;
    companyName: string;
    companyId: number;
    companyIdOMNI: number;
    email: string;
    jobTitle: string;
    pointsOfContact: {
        businessDevelopment: IPointOfContact;
        crm: IPointOfContact;
        regulatoryGFR: IPointOfContact;
        regulatoryRLS: IPointOfContact;
        data: IPointOfContact;
        legal: IPointOfContact;
        compliance: IPointOfContact;
        finance: IPointOfContact;
        it: IPointOfContact;
    };
    stateCode: number;
    statusCode: number;
    enabled2fa: boolean;
}

export const fallbackUser: IAppUser = {
    firstName: "",
    lastName: "",
    roles: [],
    companyId: -1,
    companyIdOMNI: null,
    companyName: "",
    email: "",
    jobTitle: "",
    pointsOfContact: {
        businessDevelopment: fallbackPointOfContact,
        crm: fallbackPointOfContact,
        regulatoryGFR: fallbackPointOfContact,
        regulatoryRLS: fallbackPointOfContact,
        data: fallbackPointOfContact,
        legal: fallbackPointOfContact,
        compliance: fallbackPointOfContact,
        finance: fallbackPointOfContact,
        it: fallbackPointOfContact,
    },
    stateCode: 0,
    statusCode: 1,
    enabled2fa: false,
};

export interface IServiceId {
    acl_name: string;
}

export interface IService {
    acl_acolinserviceid: IServiceId;
    acl_contractnumber: string;
    acl_name: string;
    acl_startdate: string;
    acl_enddate: string;
    acl_revisionnumber: number;
    acl_fundid: string;
    businessunitid: string;
}

export interface ILoginResponse {
    email: string;
    hash: string;
}

export interface IDate {
    year: number;
    quarter?: number;
    month?: number;
    text: string;
}

export type Option = {
    id: string;
    label: string;
};

export enum TableColumnType {
    STRING = "string",
    NUMBER = "number",
    DATE = "date",
    OPTION = "option",
}

export enum TableColumnNumberFormat {
    PERCENT = "0%",
    PERCENT_TWO_DECIMALS = "0.00%",
    TWO_DECIMALS = "0.00",
    LONG = "#,##0.00",
}

export type TableHeader<T> = {
    label: string;
    field: keyof T;
    type?: TableColumnType;
    isSortDisabled?: boolean;
    exportColumnWidth?: number;
    exportNumberFormat?: TableColumnNumberFormat;
    exportShouldNormalizePercent?: boolean;
    exportDateFormat?: string;
};

export type DistributorTab = "general" | "subDps";

export type OnboardingTab = "Funds" | "Distribution" | "Paying Agent" | "Contacts" | "Confirmation";
export type AcolinServiceOwner = {
    serviceId: string;
    serviceName: string;
    ownerName: string;
    serviceDescription: string;
    ownerEmail: string;
};

export type DashboardData = {
    TF?: Record<string, number>;
    TF_ADJUSTED?: Record<string, number>;
    TF_AUS?: Record<string, number>;
    TF_TREND?: Record<string, number>;
    AUS?: Record<string, number>;
    FR?: Record<string, number>;
    FRA?: Record<string, number>;
};

export enum FilterOption {
    AssetClass = "assetClassList",
    Fund = "fundList",
    SubFund = "subFundList",
    Isin = "isinList",
    DistributorCountry = "distributorCountryList",
    InvestorCountry = "investorCountryList",
    DistributorType = "distributorTypeList",
    Distributor = "distributorList",
    Investor = "investorList",
    FeeType = "feeTypeList",
}

export type FilterOptions = {
    assetClassList: string[];
    fundList: string[];
    subFundList: string[];
    isinList: string[];
    distributorCountryList: string[];
    investorCountryList: string[];
    distributorTypeList: string[];
    distributorList: string[];
    investorList: string[];
    feeTypeList: string[];
};

export type CountryCodesByContinent = {
    [continent: string]: string[];
};

export type CountryInfos = {
    name: string;
    isoa2code: string;
    investmentOpportunities: string;
    acolinScopeOfServices: string;
    countryFacts: string;
    expectedFees: string;
    investorsAndDistributors: string;
    registration: string;
};
