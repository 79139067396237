import React, { useEffect } from "react";
import { Card, CardContent } from "@components/common";
import "../InitiateOnboarding.scss";
import { FieldWrapper } from "@progress/kendo-react-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { StepAgentForm } from "./types";
import { stepAgentFormDetails } from "../getInitialForms";

const baseClass = "acl-page-initiate-onboarding";

type Props = {
    state: StepAgentForm;
    setState: React.Dispatch<React.SetStateAction<StepAgentForm>>;
};

const StepPayingAgent: React.FC<Props> = ({ state, setState }) => {
    const { form: forms, selectedAgent } = state;

    const updateForm = (fieldName, newValue): void => {
        if (selectedAgent === "Other") {
            setState(prevState => {
                const form = prevState.form;

                return { ...prevState, form: { ...form, [fieldName]: newValue } };
            });
        }
    };

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            selected:
                selectedAgent === "Other" ||
                selectedAgent === "Banque Cantonale Vaudoise (BCV)" ||
                selectedAgent === "Neue Privat Bank AG (NPB)" ||
                selectedAgent === "Banque Cantonale de Genève (BCGE)",
        }));
    }, [selectedAgent]);

    useEffect(() => {
        if (selectedAgent !== "Other") {
            setState(prevState => ({
                ...prevState,
                form: {
                    ...prevState.form,
                    payingAgentName: "",
                    person: "",
                    phone: "",
                    email: "",
                    address: "",
                },
            }));
        }
    }, [selectedAgent]);

    return (
        <Card className={`${baseClass}__overview`}>
            <CardContent>
                <div className={`${baseClass}__indentedContent`}>
                    <p>
                        Note: According to Swiss Law, in order to offer foreign funds in Switzerland, it is necessary to appoint a Swiss bank to act as a Swiss
                        Paying Agent.
                        <br />
                        Acolin can assist you with this as well.
                    </p>
                    <FieldWrapper>
                        <p>Choose an Acolin recommended Paying Agent or add your own</p>
                        <DropDownList
                            data={["Banque Cantonale Vaudoise (BCV)", "Neue Privat Bank AG (NPB)", "Banque Cantonale de Genève (BCGE)", "Other"]}
                            className={`${baseClass}__dropdown`}
                            onChange={(e): void => setState(prevState => ({ ...prevState, selectedAgent: e.value }))}
                            defaultValue={state.selectedAgent || "Select..."}
                        />
                    </FieldWrapper>
                    {selectedAgent === "Other" && (
                        <Card className={`${baseClass}__distributorContactForm`}>
                            <CardContent>
                                {Object.entries(forms).map(([fieldKey, _fieldValue]) => (
                                    <div key={fieldKey} className={`${baseClass}__stepPayingAgentFields`}>
                                        <label>{stepAgentFormDetails[fieldKey]}</label>
                                        <Input value={forms[fieldKey]} onChange={(e): void => updateForm(fieldKey, e.value)} />
                                    </div>
                                ))}
                            </CardContent>
                        </Card>
                    )}
                    <p className={`${baseClass}__stepPayingAgentMessage`}>
                        For any questions or additional information, please contact us at{" "}
                        <a href="mailto:fundsregulationsch@acolin.com">fundsregulationsch@acolin.com</a> .
                    </p>
                </div>
            </CardContent>
        </Card>
    );
};

export default StepPayingAgent;
