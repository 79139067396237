import React, { useCallback } from "react";
import "../InitiateOnboarding.scss";
import { Label } from "@progress/kendo-react-labels";
import { Fund, FundFile, FundType, SingleFund, SingleFundFiles, SubFund, SubFundFiles, UmbrellaFiles, UmbrellaFund } from "../../types";
import { Card, CardContent, Chip, DragAndDrop, FieldWrapper } from "@components/common";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { format } from "date-fns";
import { API_ENDPOINT } from "@src/common/config";
import axios from "@src/common/http";
import { useAppUserContext } from "@src/common/Context";
import { ERole } from "@src/common/types";

const baseClass = "acl-page-initiate-onboarding";

type FundDetailsProps = {
    fund: Fund;
    isDisabled?: boolean;
    updateFund: (newFund: Fund) => void;
};

const dateFormat = "yyyy-MM-dd";

const downloadFile = async (file: FundFile): Promise<any> => {
    const { rlsWizardId, externalId } = file || {};
    if (!rlsWizardId || !externalId) {
        console.error(`Missing file info for download.`);
        return;
    }
    const { data } = await axios.get(`${API_ENDPOINT}/rls-wizard/${rlsWizardId}/download-file/${externalId}`);
    const { name, buffer } = data;
    const url = window.URL.createObjectURL(new Blob([new Uint8Array(buffer.data)], { type: buffer?.type }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
};

const UmbrellaDetails: React.FC<FundDetailsProps> = ({ fund, updateFund, isDisabled = false }) => {
    const { name, details: fundDetails } = fund;
    const details = fundDetails as UmbrellaFund;
    const { domicile, fiscalYearEnd, regulatorName, fundOfferingToBoth, isRegulated, ucits, files } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        ucitsAttestationFiles,
    } = files;

    const updateFiles = useCallback(
        (filesKey: keyof UmbrellaFiles, newFiles): void => {
            const newDetails: UmbrellaFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof UmbrellaFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    const disableUcits = useCallback(() => {
        const newDetails: UmbrellaFund = { ...details, ucits: false, files: { ...files, ucitsAttestationFiles: [] } };
        updateFund({ ...fund, details: { ...newDetails } });
    }, [fund, details, files, updateFund]);

    const setIsRegulated = useCallback(
        (value: boolean) => {
            if (value) {
                updateFund({ ...fund, details: { ...details, isRegulated: true } });
            } else {
                updateFund({ ...fund, details: { ...details, isRegulated: false, fundOfferingToBoth: false } });
            }
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip name={"Umbrella"} size="small" type="disabled" />
            <FieldWrapper>
                <Label>Fund Name</Label>
                <Input
                    type="text"
                    placeholder="Enter Fund Name"
                    value={name}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFund({ ...fund, name: event.value })}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Fund Domicile</Label>
                <Input
                    type="text"
                    placeholder="Enter Fund Domicile"
                    value={domicile}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFundDetails("domicile", event.value)}
                />
            </FieldWrapper>

            <FieldWrapper>
                <div className={`${baseClass}__labeledRadioGroup`}>
                    <Label>UCITS</Label>
                    <div>
                        <RadioButton name="ucits" checked={ucits} label="Yes" onChange={(): void => updateFundDetails("ucits", true)} />
                        <RadioButton name="ucits" checked={ucits !== undefined && !ucits} label="No" onChange={(): void => disableUcits()} />
                    </div>
                </div>
            </FieldWrapper>

            <FieldWrapper>
                <Label>Fiscal Year End</Label>
                <DatePicker
                    value={fiscalYearEnd ? new Date(fiscalYearEnd) : null}
                    format={dateFormat}
                    placeholder="Enter Fiscal Year End"
                    onChange={(e): void => updateFundDetails("fiscalYearEnd", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>

            <FieldWrapper>
                <div className={`${baseClass}__labeledRadioGroup`}>
                    <Label>Is the fund regulated / supervised by governmental authority?</Label>
                    <div>
                        <RadioButton name="isRegulated" checked={isRegulated} label="Yes" onChange={(): void => setIsRegulated(true)} />
                        <RadioButton
                            name="isRegulated"
                            checked={isRegulated !== undefined && !isRegulated}
                            label="No"
                            onChange={(): void => setIsRegulated(false)}
                        />
                    </div>
                </div>
            </FieldWrapper>

            {isRegulated && (
                <FieldWrapper>
                    <Label>Regulator / Supervisor Authority Name</Label>
                    <Input
                        type="text"
                        placeholder="Enter Regulator / Supervisor Authority Name"
                        value={regulatorName}
                        className={`${baseClass}__inputTitle`}
                        onChange={(event): void => updateFundDetails("regulatorName", event.value)}
                    />
                </FieldWrapper>
            )}

            {isRegulated && (
                <FieldWrapper>
                    <RadioButton
                        name="fundOfferingToBoth"
                        checked={fundOfferingToBoth}
                        label="The fund will be offered to Non-Qualified and Qualified Investors in Switzerland (registration with FINMA necessary)"
                        onChange={(): void => updateFundDetails("fundOfferingToBoth", true)}
                    />
                </FieldWrapper>
            )}
            <FieldWrapper>
                <RadioButton
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth !== undefined && !fundOfferingToBoth}
                    label="The fund will be offered only to Qualified Investors in Switzerland"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", false)}
                />
            </FieldWrapper>
            <div className={`${baseClass}__fileList`}>
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="PR"
                    label="Latest Prospectus / OM / PPM"
                    files={latestProspectusFiles ?? []}
                    setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="AR"
                    label="Latest Annual Report"
                    files={latestAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="SAR"
                    label="Latest Semi-Annual Report"
                    files={latestSemiAnnualReportFiles ?? []}
                    setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="AoA"
                    label="Articles of Association"
                    files={articlesOfAssociationFiles ?? []}
                    setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="ASL"
                    label="List of the Authorized Signatories for the Fund and the Fund Provider"
                    files={authorizedSignatoriesFiles ?? []}
                    setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                    onFileClick={downloadFile}
                />
                {ucits && (
                    <DragAndDrop
                        disableUpload={isDisabled}
                        docType="UCITS-att"
                        label="UCITS Attestation"
                        files={ucitsAttestationFiles ?? []}
                        setFiles={newFiles => updateFiles("ucitsAttestationFiles", newFiles)}
                        onFileClick={downloadFile}
                    />
                )}
            </div>
        </div>
    );
};

const SingleFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund, isDisabled = false }) => {
    const { name, details: fundDetails } = fund;
    const details: SingleFund = fundDetails as SingleFund;
    const { authorizationDate, fiscalYearEnd, domicile, files, fundOfferingToBoth, isRegulated, regulatorName, ucits } = details;
    const {
        articlesOfAssociationFiles,
        authorizedSignatoriesFiles,
        latestAnnualReportFiles,
        latestProspectusFiles,
        latestSemiAnnualReportFiles,
        marketingFiles,
        priipsKidsFiles,
        ucitsAttestationFiles,
    } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SingleFundFiles, newFiles) => {
            const newDetails: SingleFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof SingleFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    const disableUcits = useCallback(() => {
        const newDetails: UmbrellaFund = { ...details, ucits: false, files: { ...files, ucitsAttestationFiles: [] } };
        updateFund({ ...fund, details: { ...newDetails } });
    }, [fund, details, files, updateFund]);

    const setIsRegulated = useCallback(
        (value: boolean) => {
            if (value) {
                updateFund({ ...fund, details: { ...details, isRegulated: true } });
            } else {
                updateFund({ ...fund, details: { ...details, isRegulated: false, fundOfferingToBoth: false } });
            }
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip name={"Single Fund"} size="small" type="disabled" />
            <FieldWrapper>
                <Label>Fund Name</Label>
                <Input
                    type="text"
                    placeholder="Enter Fund Name"
                    value={name}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFund({ ...fund, name: event.value })}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Fund Domicile</Label>
                <Input
                    type="text"
                    placeholder="Enter Fund Domicile"
                    value={domicile}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFundDetails("domicile", event.value)}
                />
            </FieldWrapper>

            <FieldWrapper>
                <div className={`${baseClass}__labeledRadioGroup`}>
                    <Label>UCITS</Label>
                    <div>
                        <RadioButton name="ucits" checked={ucits} label="Yes" onChange={(): void => updateFundDetails("ucits", true)} />
                        <RadioButton name="ucits" checked={ucits !== undefined && !ucits} label="No" onChange={(): void => disableUcits()} />
                    </div>
                </div>
            </FieldWrapper>

            <FieldWrapper>
                <Label>Fiscal Year End</Label>
                <DatePicker
                    value={fiscalYearEnd ? new Date(fiscalYearEnd) : null}
                    format={dateFormat}
                    placeholder="Enter Fiscal Year End"
                    onChange={(e): void => updateFundDetails("fiscalYearEnd", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>

            <FieldWrapper>
                <div className={`${baseClass}__labeledRadioGroup`}>
                    <Label>Is the fund regulated / supervised by governmental authority?</Label>
                    <div>
                        <RadioButton name="isRegulated" checked={isRegulated} label="Yes" onChange={(): void => setIsRegulated(true)} />
                        <RadioButton
                            name="isRegulated"
                            checked={isRegulated !== undefined && !isRegulated}
                            label="No"
                            onChange={(): void => setIsRegulated(false)}
                        />
                    </div>
                </div>
            </FieldWrapper>

            {isRegulated && (
                <>
                    <FieldWrapper>
                        <Label>Regulator / Supervisor Authority Name</Label>
                        <Input
                            type="text"
                            placeholder="Enter Regulator / Supervisor Authority Name"
                            value={regulatorName}
                            className={`${baseClass}__inputTitle`}
                            onChange={(event): void => updateFundDetails("regulatorName", event.value)}
                        />
                    </FieldWrapper>
                    <FieldWrapper>
                        <Label>Home Country Authorization Date</Label>
                        <DatePicker
                            value={authorizationDate ? new Date(authorizationDate) : null}
                            format={dateFormat}
                            placeholder="Enter Authorization Date"
                            onChange={(e): void => updateFundDetails("authorizationDate", e.target.value && format(new Date(e.target.value), dateFormat))}
                        />
                    </FieldWrapper>
                </>
            )}

            {isRegulated && (
                <FieldWrapper>
                    <RadioButton
                        name="fundOfferingToBoth"
                        checked={fundOfferingToBoth}
                        label="The fund will be offered to Non-Qualified and Qualified Investors in Switzerland (registration with FINMA necessary)"
                        onChange={(): void => updateFundDetails("fundOfferingToBoth", true)}
                    />
                </FieldWrapper>
            )}
            <FieldWrapper>
                <RadioButton
                    name="fundOfferingToBoth"
                    checked={fundOfferingToBoth !== undefined && !fundOfferingToBoth}
                    label="The fund will be offered only to Qualified Investors in Switzerland"
                    onChange={(): void => updateFundDetails("fundOfferingToBoth", false)}
                />
            </FieldWrapper>
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PR"
                label="Latest Prospectus / OM / PPM"
                files={latestProspectusFiles ?? []}
                setFiles={newFiles => updateFiles("latestProspectusFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="AR"
                label="Latest Annual Report"
                files={latestAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="SAR"
                label="Latest Semi-Annual Report"
                files={latestSemiAnnualReportFiles ?? []}
                setFiles={newFiles => updateFiles("latestSemiAnnualReportFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="AoA"
                label="Articles of Association"
                files={articlesOfAssociationFiles ?? []}
                setFiles={newFiles => updateFiles("articlesOfAssociationFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="ASL"
                label="List of the Authorized Signatories for the Fund and the Fund Provider"
                files={authorizedSignatoriesFiles ?? []}
                setFiles={newFiles => updateFiles("authorizedSignatoriesFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="MKTG"
                label="Marketing Documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PRP"
                label="PRIIPs KIDs"
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
            {ucits && (
                <DragAndDrop
                    disableUpload={isDisabled}
                    docType="UCITS-att"
                    label="UCITS Attestation"
                    files={ucitsAttestationFiles ?? []}
                    setFiles={newFiles => updateFiles("ucitsAttestationFiles", newFiles)}
                    onFileClick={downloadFile}
                />
            )}
        </div>
    );
};

const SubFundDetails: React.FC<FundDetailsProps> = ({ fund, updateFund, isDisabled = false }) => {
    const { name, details: fundDetails } = fund;
    const details: SubFund = fundDetails as SubFund;
    const { authorizationDate, files } = details;
    const { marketingFiles, priipsKidsFiles, prospectusSupplementFiles } = files || {};

    const updateFiles = useCallback(
        (filesKey: keyof SubFundFiles, newFiles) => {
            const newDetails: SubFund = { ...details, files: { ...files, [filesKey]: newFiles } };
            const newFund = { ...fund, details: newDetails };
            updateFund(newFund);
        },
        [fund, details, files, updateFund],
    );

    const updateFundDetails = useCallback(
        (detailsKey: keyof SubFund, value): void => {
            updateFund({ ...fund, details: { ...details, [detailsKey]: value } });
        },
        [fund, details, updateFund],
    );

    return (
        <div>
            <Chip name={"Subfund"} size="small" type="disabled" />
            <FieldWrapper>
                <Label>Subfund Name</Label>
                <Input
                    type="text"
                    placeholder="Enter Subfund Name"
                    value={name}
                    className={`${baseClass}__inputTitle`}
                    onChange={(event): void => updateFund({ ...fund, name: event.value })}
                />
            </FieldWrapper>
            <FieldWrapper>
                <Label>Home Country Authorization Date</Label>
                <DatePicker
                    value={authorizationDate ? new Date(authorizationDate) : null}
                    format={dateFormat}
                    placeholder="Enter Authorization Date"
                    onChange={(e): void => updateFundDetails("authorizationDate", e.target.value && format(new Date(e.target.value), dateFormat))}
                />
            </FieldWrapper>
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PR"
                label="Prospectus / Supplement"
                files={prospectusSupplementFiles ?? []}
                setFiles={newFiles => updateFiles("prospectusSupplementFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="MKTG"
                label="Marketing Documents"
                files={marketingFiles ?? []}
                setFiles={newFiles => updateFiles("marketingFiles", newFiles)}
                onFileClick={downloadFile}
            />
            <DragAndDrop
                disableUpload={isDisabled}
                docType="PRP"
                label="PRIIPs KIDs"
                files={priipsKidsFiles ?? []}
                setFiles={newFiles => updateFiles("priipsKidsFiles", newFiles)}
                onFileClick={downloadFile}
            />
        </div>
    );
};

const FundDetails: React.FC<FundDetailsProps> = ({ fund: formData, updateFund: setFormData }) => {
    const [appUser] = useAppUserContext();
    const isDisabled = !appUser.roles.includes(ERole.DocumentManager);

    return (
        <Card className={`${baseClass}__overview`}>
            <CardContent>
                {formData?.fundType === FundType.UMBRELLA && <UmbrellaDetails fund={formData} updateFund={setFormData} isDisabled={isDisabled} />}
                {formData?.fundType === FundType.SINGLE_FUND && <SingleFundDetails fund={formData} updateFund={setFormData} isDisabled={isDisabled} />}
                {formData?.fundType === FundType.SUBFUND && <SubFundDetails fund={formData} updateFund={setFormData} isDisabled={isDisabled} />}
            </CardContent>
        </Card>
    );
};
export default FundDetails;
