import React, { useState } from "react";
import qrcode from "qrcode";

import { Form, FormElement, Field, FormRenderProps } from "@progress/kendo-react-form";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Switch } from "@progress/kendo-react-inputs";

import { useAppUserContext, useNotificationContext } from "@src/common/Context";
import { Button, LabelInput, Icon, Link } from "@components/common";
import { requiredValidator } from "@src/common/util";
import axios from "@src/common/http";

import "./TwoFactorAuth.scss";

const baseClass = "acl-two-factor-auth";

const TwoFactorAuth: React.FC<{}> = () => {
    const [appUser, setAppUser] = useAppUserContext();
    const { sendNotification } = useNotificationContext();
    const [isEnabled, setIsEnabled] = useState<boolean>(appUser.enabled2fa);
    const [confirmationDialogVisible, setConfirmationDialogVisible] = useState<boolean>(false);
    const [confirmDisableVisible, setConfirmDisableVisible] = useState<boolean>(false);
    const [instructionsDialogVisible, setInstructionsDialogVisible] = useState<boolean>(false);
    const [secretKey, setSecretKey] = useState<string>(null);
    const [qrcodeUrl, setQrcodeUrl] = useState<string>(null);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [validate2faError, setValidate2faError] = useState<string>(null);

    const toggleDialog = (): void => {
        setConfirmationDialogVisible(!confirmationDialogVisible);
    };

    const closeInstructionsDialog = (): void => {
        setInstructionsDialogVisible(false);
    };

    const onInitialize = (): void => {
        setInstructionsDialogVisible(false);
        toggleDialog();
    };

    async function initialize2Fa(): Promise<void> {
        const response = await axios.get(`/two-factor-authentication`, { params: { email: appUser.email, action: "initialize" } });

        if (response.status === 200) {
            setSecretKey(response.data.secret);
            const res = await qrcode.toDataURL(response.data["authUri"]);
            setQrcodeUrl(res);
            setInstructionsDialogVisible(true);
        }
    }

    async function enable2fa(formData: Record<string, string>, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();

        setSubmitting(true);

        const response = await axios.get(`/two-factor-authentication`, { params: { email: appUser.email, action: "enable", token: formData.token } });
        const { status, data } = response;

        if (status === 200) {
            setValidate2faError(null);
            sendNotification({
                timeout: 6000,
                type: "success",
                message: data.message,
            });
            setAppUser({
                ...appUser,
                enabled2fa: true,
            });
            toggleDialog();
            setIsEnabled(!isEnabled);
        } else {
            setValidate2faError(data.error.message);
        }
        setSubmitting(false);
    }
    async function disable2Fa(formData: { token: string }, e?: React.SyntheticEvent): Promise<void> {
        e.preventDefault();

        setSubmitting(true);
        try {
            const response = await axios.get(`/two-factor-authentication`, { params: { email: appUser.email, action: "disable", token: formData.token } });
            const { status, data } = response;
            if (status === 200) {
                sendNotification({
                    timeout: 6000,
                    type: "success",
                    message: data.message,
                });
                setIsEnabled(!isEnabled);
                setAppUser({
                    ...appUser,
                    enabled2fa: false,
                });
                setConfirmDisableVisible(false);
            } else {
                setValidate2faError(data.error.message);
            }
        } catch (error) {
            console.error("Error disabling 2FA", error);
            sendNotification({
                timeout: 6000,
                type: "error",
                message: error.response.data.error || "Failed to disable 2FA",
            });
        } finally {
            setSubmitting(false);
        }
    }

    const handleSwitchChange = (e): void => {
        e.value ? initialize2Fa() : setConfirmDisableVisible(true);
    };

    const handleKeyPress = (e): void => e.key === "Enter" && e.preventDefault();

    return (
        <div className={`${baseClass}`} data-testid="2fa">
            <p>You can enable two-factor authentication using one of the popular apps like Authy, Microsoft or Google Authenticator.</p>
            <div className={`${baseClass}__switch-wrapper`}>
                <Switch onLabel={"On"} offLabel={"Off"} id="twoFASwitch" checked={isEnabled} onChange={handleSwitchChange} />
            </div>

            {instructionsDialogVisible && (
                <Dialog
                    data-testId="2faInstructions"
                    title={"2FA Instructions"}
                    onClose={(): void => setInstructionsDialogVisible(false)}
                    className={`${baseClass}__2fa-dialog`}
                >
                    <p>
                        To enable two-factor authentication, please use one of the popular apps like{" "}
                        <Link to="https://authy.com/features/setup/" target="_blank">
                            Authy
                        </Link>
                        ,{" "}
                        <Link to="https://www.microsoft.com/en-us/security/mobile-authenticator-app" target="_blank">
                            Microsoft
                        </Link>{" "}
                        or{" "}
                        <Link to="https://support.google.com/accounts/answer/1066447?hl=en&ref_topic=2954345" target="_blank">
                            Google Authenticator
                        </Link>
                        .
                        <br />
                        You can do this in two ways:
                    </p>
                    <p>
                        Authenticating with secret key: <strong>{secretKey}</strong>
                    </p>
                    <p>Authenticating by QR code:</p>
                    <img src={qrcodeUrl} alt="Acolin" />
                    <footer className={`${baseClass}__actions`}>
                        <Button fill="outlined" onClick={closeInstructionsDialog}>
                            Cancel
                        </Button>
                        <Button themeColor="primary" onClick={onInitialize}>
                            Next Step
                        </Button>
                    </footer>
                </Dialog>
            )}

            {confirmationDialogVisible && (
                <Dialog title={"Please Verify"} onClose={toggleDialog}>
                    <p>Please enter the code you generated via your authentication app here.</p>
                    <Form
                        aria-busy={submitting ? "true" : null}
                        onSubmit={enable2fa}
                        render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                            <FormElement className={`${baseClass}__form`} noValidate={true}>
                                <Field
                                    name="token"
                                    label="Two-Factor Authentication"
                                    id="token"
                                    ariaDescribedBy="tokenError"
                                    component={LabelInput}
                                    validator={requiredValidator}
                                    required={true}
                                    className={`${baseClass}__field`}
                                    onKeyPress={handleKeyPress}
                                />
                                {validate2faError && <p className={`${baseClass}__token-error`}>{validate2faError} Please try again</p>}
                                <div className={`k-form-buttons ${baseClass}__actions`}>
                                    <Button fill="outlined" onClick={toggleDialog}>
                                        Cancel
                                    </Button>
                                    <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting}>
                                        {submitting && <Icon name="loading" spacing="right" />}
                                        Verify
                                    </Button>
                                </div>
                            </FormElement>
                        )}
                    />
                </Dialog>
            )}
            {confirmDisableVisible && (
                <Dialog title={"Disable 2FA"} onClose={() => setConfirmDisableVisible(false)} className={`${baseClass}__2fa-dialog`}>
                    <p>You are about the disable Two-Factor Authentication. Enter your 2FA code to confirm.</p>
                    <p>Please enter the code you generated via your authentication app here.</p>
                    <Form
                        aria-busy={submitting ? "true" : null}
                        onSubmit={disable2Fa}
                        render={({ valid, allowSubmit }: FormRenderProps): React.ReactElement => (
                            <FormElement className={`${baseClass}__form`} noValidate={true}>
                                <Field
                                    name="token"
                                    label="Two-Factor Authentication"
                                    id="token"
                                    ariaDescribedBy="tokenError"
                                    component={LabelInput}
                                    validator={requiredValidator}
                                    required={true}
                                    className={`${baseClass}__field`}
                                    onKeyPress={handleKeyPress}
                                />
                                {validate2faError && <p className={`${baseClass}__token-error`}>{validate2faError} Please try again</p>}
                                <div className={`k-form-buttons ${baseClass}__actions`}>
                                    <Button fill="outlined" onClick={() => setConfirmDisableVisible(false)}>
                                        Cancel
                                    </Button>
                                    <Button themeColor="primary" type="submit" disabled={!allowSubmit || !valid || submitting}>
                                        {submitting && <Icon name="loading" spacing="right" />}
                                        Verify
                                    </Button>
                                </div>
                            </FormElement>
                        )}
                    />
                    <p>Contact your Portal Admin or Acolin Helpdesk if you are unable to access your 2FA app.</p>
                </Dialog>
            )}
        </div>
    );
};

export default TwoFactorAuth;
